/* 3rd party styles */
@import "~bootstrap/scss/bootstrap-grid";

@import "1-base/variables";
@import "2-global/default";
@import "2-global/common";
@import "2-global/spacing";
@import "3-pages/coming-soon-01";
@import "3-pages/coming-soon-02";
@import "3-pages/coming-soon-03";
@import "3-pages/coming-soon-04";
@import "3-pages/coming-soon-05";
@import "3-pages/coming-soon-06";
@import "3-pages/coming-soon-07";
@import "3-pages/coming-soon-08";
@import "3-pages/coming-soon-09";
@import "3-pages/coming-soon-10";
@import "3-pages/coming-soon-11";
@import "3-pages/coming-soon-12";
@import "3-pages/coming-soon-13";
@import "3-pages/coming-soon-14";
@import "3-pages/coming-soon-15";
@import "3-pages/coming-soon-16";
@import "3-pages/coming-soon-17";
@import "3-pages/coming-soon-18";
@import "3-pages/coming-soon-19";
@import "3-pages/coming-soon-20";
@import "landing.scss";
